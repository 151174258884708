<template>
  <div class="statistics">
    <b-row>
      <b-col>
        <b-card>
          <line-chart
            :height="400"
            :data="paymentsData"
            :options="paymentsOptions"
            :plugins="plugins"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import LineChart from '@/views/finances/components/LineChart'
export default {
  components: { LineChart },
  data() {
    return {
      paymentsData: {
        labels: [
          0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140,
        ],
        datasets: [
          {
            data: [
              80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290,
              360, 375,
            ],
            label: 'Начисленные проценты',
            borderColor: '#ff4961',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#ff4961',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: '#fff',
            pointHoverBackgroundColor: '#ff4961',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: '#666',
          },
          {
            data: [
              80, 99, 82, 90, 115, 115, 74, 75, 130, 155, 125, 90, 140, 130,
              180,
            ],
            label: 'Уплаченные проценты',
            borderColor: '#ffe802',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#ffe802',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: '#fff',
            pointHoverBackgroundColor: '#ffe802',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: '#666',
          },
        ],
      },
      paymentsOptions: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: '#666',
          backgroundColor: '#fff',
          titleFontColor: '#000',
          bodyFontColor: '#000',
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: '#727272',
                zeroLineColor: '#727272',
              },
              ticks: {
                fontColor: '#6e6b7b',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: 400,
                fontColor: '#6e6b7b',
              },
              gridLines: {
                display: true,
                color: '#727272',
                zeroLineColor: '#727272',
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      },
      plugins: [],
    }
  },
}
</script>
